import { call, put } from 'redux-saga/effects'
import { http } from 'Utils/http'
import { globalError } from './shell'
import { messageLevel } from 'Utils/constants'
import { t } from 'Root/app/IntlProvider'
import { showMessage } from 'Actions/shell'

export function* addSaga(action) {
  const { payload } = action
  const { resolve, values } = payload
  const { ok, error, data, notFound } = yield http.post('/work-orders', values)
  if (ok) {
    return yield call(resolve, true)
  } else if (notFound) {
    yield put(showMessage(t('message.notFound'), messageLevel.warning))
  } else if (error) {
    yield call(globalError, data)
  }
  yield call(resolve, false)
}
